import React from 'react';
import { Box } from '@chakra-ui/react';

const EventsContent = () => {

  return (
    <Box mr="2" ml="2">
      This is the Events page! Content coming soon!
    </Box>
  )
}

export default EventsContent;